<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style lang="scss" scoped>
    #app {
        height: 100%;
    }
    @media screen and (max-width: 991px) {
        #app{
            &.active{
                overflow: hidden;
            }
        }
    }
</style>