import axios from 'axios'
import StoreCache from '@/utils/StoreCache'

const cached = {
    forums: {
        ids: {}, // new StoreCache(5 * 60 * 1000),
        lifetime: 5 * 60 * 1000, // 5 min
    },
};

const state = {
    forums: {},
};

const getters = {
    forumByID(state){
        return id => {
            return state.forums[id]
        }
    },

    eventsByForumId(state){
        return id => {
            return state.forums[id].events
        }
    },
};

const mutations = {
    setForumsList(state, forums){
        for (let i = 0, len = forums.results.length; i < len; i++) {
            const forum = forums.results[i];
            const id = forum._id;

            //Проверяем есть уже такой форум в кеше
            if (!(id in cached.forums.ids)) {
                cached.forums.ids[id] = new StoreCache(cached.forums.lifetime);
            }

            state.forums[id] = forum;
            cached.forums.ids[id].fix();
        }

    },

    setForumById(state, forum){
        const id = forum._id;
        state.forums[ id ] = forum;

        // if (id in cached.forums.ids) {
        //     cached.forums.ids[id].reset();
        // }
    },

    deleteForumById(state, id){
        delete state.forums[id]
    },

    setForumImg(state, { forumId, image }){
        state.forums[ forumId ].imagePath = image;
    },

    setSpeakerByForumId(state, speaker){
        state.forums[ speaker.forumId ].speakers.push(speaker);
    },

    deleteSpeaker(state, { forumId, speakerId }){
        const speakers = state.forums[forumId].speakers;
        const filteredSpeakers = speakers.filter(speaker => speaker._id !== speakerId);
        state.forums[forumId].speakers = filteredSpeakers;
    },

    saveSpeakerByForumId(state, speakerApiData){
        const speakers = state.forums[ speakerApiData.forumId ].speakers;
        for (let i = 0, len = speakers.length; i < len; i++) {
            const speaker = speakers[i];
            if(speaker._id === speakerApiData._id){
                state.forums[ speakerApiData.forumId ].speakers[i] = speakerApiData;
                break;
            }
        }
    },

    //Сбросить стор после разлогина
    resetForums(state) {
        state.forums = {};
        cached.forums = {
            ids: {}
        };
    },

    setEventByForumId(state, event){
        state.forums[ event.forumId ].events.push(event);
    },

    setEventSpeakerByForumId(state, event){
        const events = state.forums[ event.forumId ].events;
        events.map((item, index) => {
            if(item._id === event._id){
                events[index].speakers = event.speakers;
            }
        });
    },

    deleteEventByForumId(state, { eventId, forumId }){
        state.forums[ forumId ].events = state.forums[ forumId ].events.filter(event => {
            return event._id !== eventId
        });
    },

    editEventByForumId(state, event){
        state.forums[ event.forumId ].events.map((item, index) => {
            if(item._id === event._id){
                state.forums[ event.forumId ].events[index] = event
            }
        })
    },

    setMaterial(state, material){
        state.forums[ material.forumId ].materials.push(material)
    },

    deleteMaterial(state, { forumId, materialId }){
        const materials = state.forums[forumId].materials;
        const filteredMaterials = materials.filter(material => material._id !== materialId);
        state.forums[forumId].materials = filteredMaterials;
    },

    saveMaterialByForumId(state, materialApiData){
        const materials = state.forums[ materialApiData.forumId ].materials;
        for (let i = 0, len = materials.length; i < len; i++) {
            const material = materials[i];
            if(material._id === materialApiData._id){
                state.forums[ materialApiData.forumId ].materials[i] = materialApiData;
                break;
            }
        }
    },
};

const actions = {
    getForumsList({commit}, data) {
        const params = new URLSearchParams(data);
        return axios.get('/forum', { params }).then(({apidata}) => {
            commit('setForumsList', apidata);

            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    saveNewForum({ commit }, data){
        return axios.post('/forum', data).then(({apidata}) => {
            // Добавляем форум в стор
            commit('setForumById', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    saveEditForum({commit}, { forumId, forum }){
        return axios.put(`/forum/${forumId}`, forum).then(({apidata}) => {
            commit('setForumById', apidata);
            cached.forums.ids[forumId].reset();
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    deleteForum({ commit }, id){
        return axios.delete(`/forum/${id}`).then(({apidata}) => {
            commit('deleteForumById', id);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    saveNewSpeaker({ commit }, data){
        // Объект FormData
        let formData = new FormData();
        const fields = Object.keys(data);
        // Добавляем свойства в FormData
        for(let field of fields){
            formData.append(field, data[field]);
        }
        return axios.post('/speaker', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({apidata}) => {
            commit('setSpeakerByForumId', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    saveEditSpeaker({ commit }, { speakerId, name, description, forumId }){
        const speakerData = {
            forumId,
            speakerId,
            name,
            description
        };
        return axios.put(`/speaker/${speakerId}`, speakerData).then(({apidata}) => {
            commit('saveSpeakerByForumId', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));

    },

    saveSpeakerPhoto({ commit }, data){
        const speakerId = data.speakerId;
        let formData = new FormData();
        formData.append('photo', data.photo);

        return axios.post(`/speaker/${speakerId}/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({apidata}) => {
            commit('saveSpeakerByForumId', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    deleteSpeakerById({commit}, { forumId, speakerId }){
        return axios.delete(`/speaker/${speakerId}`).then(({apidata}) => {
            commit('deleteSpeaker', { forumId, speakerId });
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    saveForumImg({ commit }, { forumId, image }){
        // Объект FormData
        let formData = new FormData();
        formData.append('image', image);

        return axios.post(`/forum/${forumId}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({apidata}) => {
            const forumId = apidata._id;
            const image = apidata.imagePath;
            commit('setForumImg', { forumId, image});
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getForumById({ getters, commit }, id){
        //Проверяем есть ли id уже в кеше, если нет то добавляем
        if (!(id in cached.forums.ids)) {
            cached.forums.ids[id] = new StoreCache(cached.forums.lifetime);
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (cached.forums.ids[id].expired()) {
            return axios.get(`/forum/${id}`).then(({apidata}) => {
                commit('setForumById', apidata);
                cached.forums.ids[id].fix();
                return Promise.resolve( getters.forumByID(id) )
            }).catch(error => Promise.reject(error))
        } else {
            //Иначе возвращаем геттерс
            return Promise.resolve( getters.forumByID(id) )
        }
    },

    saveNewEvent({ commit }, data){
        return axios.post('/event', data).then(({apidata}) => {
            commit('setEventByForumId', apidata);
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    saveEventSpeaker({ commit }, { eventId, speakers }) {
        return axios.post(`/event/${eventId}/speaker`, speakers).then(({apidata}) => {

            commit('setEventSpeakerByForumId', apidata);

            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    deleteEvent({ commit }, { eventId, forumId }){
        return axios.delete(`/event/${eventId}`).then(({apidata}) => {
            commit('deleteEventByForumId', { eventId, forumId });

            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    editEvent({ commit }, { eventId, event }){
        return axios.put(`/event/${eventId}`, event).then(({apidata}) => {
            commit('editEventByForumId', apidata);
            cached.forums.ids[apidata.forumId].reset();
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    deleteSpeakerByEventId({ commit }, {eventId, speakerId}){
        return axios.delete(`/event/${eventId}/speaker/${speakerId}`).then(({apidata}) => {
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    saveMaterials({ commit }, material){
        return axios.post(`/material/`, material).then(({apidata}) => {
            commit('setMaterial', apidata);
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    deleteMaterialById({ commit }, { forumId, materialId }){
        return axios.delete(`/material/${materialId}`).then(({apidata}) => {
            commit('deleteMaterial', { forumId, materialId });
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    },

    editMaterialById({ commit }, data){
        return axios.put(`/material/${data._id}`, data).then(({apidata}) => {
            commit('saveMaterialByForumId', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error));
    }

};

export default {
    state,
    getters,
    mutations,
    actions,
}