import axios from 'axios'

const state = {
    iconList: [],
};

const getters = {
    icons(state){
        return state.iconList
    }
};

const mutations = {
    setIcons(state, icons){
        state.iconList = icons;
    },
    setIcon(state, icon){
        state.iconList.push(icon)
    },
    deleteIcon(state, iconId){
        const list = state.iconList.filter(icon => {
            return icon._id !== iconId
        });
        state.iconList = list;
    }
};

const actions = {
    saveIcon({ commit }, data){
        // Объект FormData
        let formData = new FormData();
        const fields = Object.keys(data);
        //Добавляем свойства в FormData
        for(let field of fields){
            formData.append(field, data[field]);
        }
        return axios.post('/icon/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({apidata}) => {
            commit('setIcon', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getIconList({ commit }){
        return axios.get(`/icon/`).then(({apidata}) => {
            commit('setIcons', apidata);
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    deleteIconById({ commit }, iconId){
        return axios.delete(`/icon/${iconId}`).then(({apidata}) => {
            commit('deleteIcon', iconId);
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}