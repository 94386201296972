/**
 * Перехватчик запросов для axios:
 * - добавляет токен авторизации в заголовке Authorization при отправке каждого запроса
 * - обрабатывает полученнй результат по запросу
 * - разлогинивает юзера при получении 401
 *
 * @param {*} Vue
 * @param {*} axios
 * @param {*} store
 * @param {*} router
 */

const X_Auth_Key = 'Authorization';
// const Accept_Language = 'Accept-Language';

function axiosMiddleware(Vue, axios, store, router) {
    axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

    // добавляем токен авторизации и локализацию
    axios.interceptors.request.use(
        config => {
            if (!config.headers.common.hasOwnProperty(X_Auth_Key)) {
                const auth_token = store.getters.auth_token;

                if (auth_token) {
                    config.headers.common[X_Auth_Key] = auth_token
                }
            }

            // config.headers.common[Accept_Language] = 'ru';

            return config
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        // обрабатываем результат
        response => {
            response.apidata = response.data

            return Promise.resolve(response)
        },
        // разлогиниваем юзера
        error => {
            if (error.response) {
                switch (error.response.status) {
                    // 401 Unauthorized
                    case 401: {
                        return store.dispatch('signOut', true).then(() => {
                            router.push({ name: 'signup', replace: true });
                            return Promise.reject(error)
                        })
                    } break

                    // 403 Forbidden
                    case 403: {
                        router.push('/');
                        return Promise.reject(error)
                    } break

                    default: {
                        return Promise.reject(error)
                    }
                }
            } else {
                return Promise.reject(error)
            }
        }
    );
}

export default axiosMiddleware
