import axios from 'axios'

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getInterests({}, data) {
        const params = new URLSearchParams(data);
        return axios.get('/user-interest/', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    deleteInterests({}, id) {
        return axios.delete(`/user-interest/${id}`).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    addInterest({}, title){
        return axios.post(`/user-interest/`, { title }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    editInterest({}, { interestId, title }){
        return axios.patch(`/user-interest/${ interestId }`, { title }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}