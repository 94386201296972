import axios from 'axios'

import storage from '@/utils/storage'

const KEY_AUTH_TOKEN = 'token';
const KEY_AUTH_DETAILS = 'user';

const state = {
    token: null,
    authDetails: null,
    userEmail: null,
};

const getters = {
    auth_token: state => state.token,

    is_auth: state => Boolean(state.token),

    firstName: state => state.authDetails ? state.authDetails.firstName : null,
    lastName: state => state.authDetails ? state.authDetails.lastName : null,
    avatarPath: state => state.authDetails ? state.authDetails.avatarPath : null,

    email(state){
        return state.userEmail
    }
};

const mutations = {

    signIn(state, { token, user }) {
        state.token = token;
        state.authDetails = typeof user === 'string' ? JSON.parse(user) : user;

        // if (!loaded) {
            storage.set(KEY_AUTH_TOKEN, token);
            storage.set(KEY_AUTH_DETAILS, typeof user === 'string' ? user : JSON.stringify(user));
        // }
    },

    setUserEmail(state, email){
        state.userEmail = email
    },

    signOut(state) {
        state.token = null;
        state.authDetails = null;

        storage.remove(KEY_AUTH_TOKEN);
        storage.remove(KEY_AUTH_DETAILS);
    },
};

const actions = {
    signIn({commit}, { email, password }) {
        const payload = {
            email,
            password,
        };

        return axios.post('/user/signin/', payload).then(({apidata}) => {
            commit('signIn', apidata);
            // return Promise.resolve(true)
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    signUp({commit}, email) {
        return axios.post('/user/signup/', email).then(({apidata}) => {
            return Promise.resolve(true)
        }).catch(error => Promise.reject(error))
    },

    signOut({commit}, is_shadow) {
        const resolve = () => {
            commit('signOut');
            return Promise.resolve(true)
        };

        commit('resetForums');
        commit('resetUsers');

        return is_shadow
            ? resolve()
            : axios.delete('/user/logout/').then(resolve).catch(resolve)
    },

    loadAuthInfo({commit, dispatch}) {
        const token = storage.get(KEY_AUTH_TOKEN);
        const user = storage.get(KEY_AUTH_DETAILS);

        if (token) {
            commit('signIn', { token, user })
            // dispatch('me').then(()=>{}).catch(()=>{})
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}