import axios from 'axios'

import StoreCache from '@/utils/StoreCache'

const cached = {
    users: {
        ids: {}, // new StoreCache(5 * 60 * 1000),
        lifetime: 5 * 60 * 1000, // 5 min
    },
};

const state = {
    users: {},
};

const getters = {
    userByID: state => (id) => state.users[id],
};

const mutations = {
    setUsers(state, users){
        for (let i = 0, len = users.results.length; i < len; i++) {
            const user = users.results[i];
            const id = user._id;

            //Проверяем есть уже такой форум в кеше
            if (!(id in cached.users.ids)) {
                cached.users.ids[id] = new StoreCache(cached.users.lifetime);
            }

            state.users[id] = user;
            cached.users.ids[id].fix();
        }
    },

    setUserById(state, user){
        const id = user._id;
        state.users[ id ] = user;
    },

    setUser(state, user){
        state.users[ user._id ] = user;
    },

    setUserAvatar(state, { userId, avatar }){
        state.users[userId].avatarPath =  avatar;
    },

    setUserRoleById(state, user){
        state.users[user._id].role = user.role;
    },

    //Сбросить стор после разлогина
    resetUsers(state) {
        state.users = {};
        cached.users = {
            ids: {}
        };
    },
};

const actions = {
    getUsers({ commit }, data) {
        const params = new URLSearchParams(data);
        return axios.get('/user/', { params }).then(({apidata}) => {
            commit('setUsers', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getUserById({ getters, commit }, id){
        //Проверяем есть ли id уже в кеше, если нет то добавляем
        if (!(id in cached.users.ids)) {
            cached.users.ids[id] = new StoreCache(cached.users.lifetime);
        }

        //Если вермя жизни хешированных данных истекло делаем запрос на сервер
        if (cached.users.ids[id].expired()) {
            return axios.get(`/user/${id}`).then(({apidata}) => {
                commit('setUserById', apidata);
                cached.users.ids[id].fix();
                return Promise.resolve( getters.userByID(id) )
            }).catch(error => Promise.reject(error))
        } else {
            //Иначе возвращаем геттерс
            return Promise.resolve( getters.userByID(id) )
        }
    },

    deleteUser({}, id){
        return axios.delete(`/user/${id}`).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getUserEvent({}, data){
        const params = new URLSearchParams(data);
        return axios.get('/forum', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    setUserStatus({ commit }, { userId, ban, language }){
        const config = {
            headers: {
                'Accept-Language': language,
            }
        };
        return axios.post(`/user/${userId}/ban`, { ban }, config).then(({apidata}) => {
            commit('setUser', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    addNewUser({ commit }, { email, firstName, lastName, language }){
        const config = {
            headers: {
                'Accept-Language': language,
            }
        };
        return axios.post(`/user/`, { email, firstName, lastName }, config).then(({apidata}) => {
            commit('setUser', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    editUser({ commit }, { userId, user, language }){
        const config = {
            headers: {
                'Accept-Language': language
            }
        };
        return axios.patch(`/user/${userId}`, user, config).then(({apidata}) => {
            commit('setUser', apidata);
            cached.users.ids[userId].reset();
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    saveUserAvatar({ commit }, { userId, avatar }){
        // Объект FormData
        let formData = new FormData();
        formData.append('avatar', avatar);
        return axios.post(`/user/${userId}/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({apidata}) => {
            const avatar = apidata.avatarPath;
            commit('setUserAvatar', { userId, avatar });
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    inviteUserForum({}, { data, language }){
        const config = {
            headers: {
                'Accept-Language': language
            }
        };
        return axios.post(`/user-forum`, data, config).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getUserForum({}, data){
        const params = new URLSearchParams(data);
        return axios.get('/user-forum', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    setStateUserForum({}, { itemId, params, language }){
        const config = {
            headers: {
                'Accept-Language': language
            }
        };
        return axios.post(`/user-forum/${ itemId }`, params, config).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    deleteRequestUserForum({}, itemId){
        return axios.delete(`/user-forum/${itemId}`).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getUserUnban({}, data){
        const params = new URLSearchParams(data);
        return axios.get('/unban', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    deleteUserUnban({}, id){
        return axios.delete(`/unban/${ id }`).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    setUserRole({ commit }, { userId, role, language }){
        const config = {
            headers: {
                'Accept-Language': language,
            }
        };
        return axios.post(`/user/${ userId }/role`, { role }, config).then(({apidata}) => {
            commit('setUserRoleById', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getClaim({}, data){
        const params = new URLSearchParams(data);
        return axios.get('/claim', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

};

export default {
    state,
    getters,
    mutations,
    actions,
}