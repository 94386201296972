import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import VueSweetalert2 from 'vue-sweetalert2'
import vSelect from 'vue-select'

// Vue Validator
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ru from 'vee-validate/dist/locale/ru.json';
localize('ru', ru);

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import "./assets/scss/style.scss";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(DatePicker);
Vue.use(VueSweetalert2);
Vue.component('v-select', vSelect);

import router from '@/router'
import store from '@/store'

import axios from 'axios'
import axiosMiddleware from '@/middlewares/axios-middleware'
Vue.use(axiosMiddleware, axios, store, router)

store.dispatch('loadAuthInfo').then(()=>{}).catch(()=>{})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
