import axios from 'axios'

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getBillings({}, data) {
        const params = new URLSearchParams(data);
        return axios.get('/billing/', { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}