import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        { path: '/', redirect: { name: 'forums' }, component: () => import('@/views/layouts/main.vue'),  children: [
                { path: 'forums',                       name: 'forums',         component: () => import('@/views/forum/forums') },
                { path: 'forums/forum-add',             name: 'forum-add',      component: () => import('@/views/forum/add') },
                { path: 'forums/:forumId',              name: 'forum-details',  component: () => import('@/views/forum/details') },
                { path: 'forums/program/:forumId',      name: 'forum-program',  component: () => import('@/views/forum/details/program') },
                { path: 'forums/edit/:forumId',         name: 'forum-edit',     component: () => import('@/views/forum/edit') },
                { path: 'users',                        name: 'users',          component: () => import('@/views/user/users') },
                { path: 'users/user-add',               name: 'user-add',       component: () => import('@/views/user/add') },
                { path: 'users/:userId',                name: 'user-details',   component: () => import('@/views/user/details') },
                { path: 'users/edit/:userId',           name: 'user-edit',      component: () => import('@/views/user/edit') },
                { path: 'registration',                 name: 'registration',   component: () => import('@/views/registration') },
                { path: 'icons',                        name: 'icons',          component: () => import('@/views/icons') },
                { path: 'unban',                        name: 'unban',          component: () => import('@/views/unban') },
                { path: 'admins',                       name: 'admins',         component: () => import('@/views/admins') },
                { path: 'claim',                        name: 'claim',          component: () => import('@/views/claim') },
                { path: 'interests',                    name: 'interests',      component: () => import('@/views/interests') },
                { path: 'billings',                     name: 'billings',       component: () => import('@/views/billings') },
                { path: '404',                          name: 'not-found',      component: () => import('@/views/not-found') },
        ],
            beforeEnter: (to, from, next) => {
                if (store.getters.is_auth) {
                    next()
                } else {
                    next({name: 'signup', replace: true})
                }
            }
        },

        { path: '/signup', name: 'signup',   component: () => import('@/views/signup'),},
        { path: '/signin', name: 'signin',   component: () => import('@/views/signin'),},
        { path: '*', redirect: { name: 'not-found' },},
    ],
    scrollBehavior(to, from, savedPosition) {
        let scroll = { x: 0, y: 0 };

        if (to.hash) {
            scroll = { selector: to.hash }
        } else if (savedPosition) {
            scroll = savedPosition
        }
        return scroll
    },
});

export default router