/**
 * The file enables `@/store/index.js` to import all vuex modules without listed in modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.js$/);
const modules = {};

files.keys().forEach(filename => {
    if (filename != './index.js') {
        modules[filename.replace(/(\.\/|\.js)/g, '')] = files(filename).default
    }
});

export default modules
